import { useEffect,useState } from "react";
import { useParams } from "react-router";
import { Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import TableColumn from "../../components/Table/TableColumn";
import UiBase from "../../base/UiBase";
import BackButton from "../../components/Buttons/BackButton";
import TableBody from "../../components/Table/TableBody";
// Project view and repository clicks history
export const ProjectStaistics =()=>{
    const {id}=useParams();
    const [records,setRecords]:Array<any>=useState([]);
    const base=new UiBase();
    const getStatsRec=async()=>{
        const results=await base.util.fetchRequestComplete("/project/get-statistics/"+id,"GET",null,true);
        if(results.ok){
            setRecords(results.json);
        }
    }
    useEffect(()=>{
        getStatsRec();
    },[]);
    return(
        <div>
        <Row>
        <Col md={2}>
        <BackButton url={"/projects"}/>
        </Col>
        <Col md={9}>
        <Table striped="columns">
        <TableColumn columns={[{name:"Project Name",size:0},{name:"View",size:0},{name:"Repository Click",size:0},{name:"Date",size:0},{name:"Time",size:0}]}/>
        <TableBody idKey={"_id"} keys={[{key:"projectName"},{key:"view"},{key:"repositoryClick"},{key:"date"},{key:"time"}]} records={records}/>
        </Table>     
        </Col>
        <Col md={4}>
             
        </Col>
        </Row>
        </div>
    );
}